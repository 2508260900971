$red: #ff644e;
$primary: #03a89d;
$primary-light: #16e7cf;
$secondary: #ff644e;
$primary-dark: #03a89d;
$bg-dark: #111116;
$bg-dark-01: #1f232b;
$bg-light: #353d4b;
$bg-light-03: #293646d0;
$bg-light-01: #29364650;
$yellow: #efb909;
$yellow-dark: #eeae00;
$dark-white: rgba(255, 255, 255, 0.8);
