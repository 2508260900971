.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel {
  position: relative;
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.carousel__item {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  padding: 0 12px;
  opacity: 0;
  filter: drop-shadow(0 2px 2px #555);
  will-change: transform, opacity;
  -webkit-animation: carousel-animate-vertical 20s linear infinite;
  animation: carousel-animate-vertical 20s linear infinite;
}

.carousel__item:nth-child(1) {
  -webkit-animation-delay: calc(2s * -1);
  animation-delay: calc(2s * -1);
}

.carousel__item:nth-child(2) {
  -webkit-animation-delay: calc(2s * 0);
  animation-delay: calc(2s * 0);
}

.carousel__item:nth-child(3) {
  -webkit-animation-delay: calc(2s * 1);
  animation-delay: calc(2s * 1);
}

.carousel__item:nth-child(4) {
  -webkit-animation-delay: calc(2s * 2);
  animation-delay: calc(2s * 2);
}

.carousel__item:nth-child(5) {
  -webkit-animation-delay: calc(2s * 3);
  animation-delay: calc(2s * 3);
}

.carousel__item:nth-child(6) {
  -webkit-animation-delay: calc(2s * 4);
  animation-delay: calc(2s * 4);
}

.carousel__item:nth-child(7) {
  -webkit-animation-delay: calc(2s * 5);
  animation-delay: calc(2s * 5);
}

.carousel__item:nth-child(8) {
  -webkit-animation-delay: calc(2s * 6);
  animation-delay: calc(2s * 6);
}

.carousel__item:nth-child(9) {
  -webkit-animation-delay: calc(2s * 7);
  animation-delay: calc(2s * 7);
}

.carousel__item:last-child {
  -webkit-animation-delay: calc(-2s * 2);
  animation-delay: calc(-2s * 2);
}

.carousel__item-head {
  border-radius: 50%;
  background-color: #d7f7fc;
  width: 45px;
  height: 45px;
  padding: 14px;
  position: relative;
  margin-right: -35px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  margin-bottom: 0;
}

.carousel__item-body {
  width: 100%;
  background-color: rgba(32, 35, 42, 0.1) !important;
  border: 1px solid rgba(32, 35, 42, 0.2);
  border-radius: 8px;
  padding: 10px 20px 10px 50px;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.title {
  text-transform: uppercase;
  margin-top: 10px;
}

@-webkit-keyframes carousel-animate-vertical {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
  3%,
  10% {
    transform: translateY(100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  13%,
  20% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }
  23%,
  30% {
    transform: translateY(-100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  33% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }
  100% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes carousel-animate-vertical {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
  3%,
  10% {
    transform: translateY(100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  13%,
  20% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }
  23%,
  30% {
    transform: translateY(-100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  33% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }
  100% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}
