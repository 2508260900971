@import "../../style/color.scss";
.main-menu {
    margin-left: 30px;
    button {
        color: #fff;
        text-transform: initial;
        min-width: 120px;
        overflow: hidden;
        font-size: 1rem;
        padding: 4px 16px !important;
    }
}
.MuiDrawer-modal {
    .MuiPaper-root {
        margin: 0px !important;
    }
}
.MuiPaper-root {
    background: $bg-light !important;
    margin-top: 5px;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.35) !important;
    ul {
        padding: 0px;
        li {
            min-height: 50px;
            min-width: 150px;
            padding: 0px 20px;
            color: #fff;
            font-size: 1rem !important;
            &:hover {
                background: rgba(255, 255, 255, 0.1);
            }
        }
    }
}

.main-drawer {
    .MuiPaper-root.MuiDrawer-paper {
        background: $bg-light;
        max-width: 100vw;
        .MuiButtonBase-root {
            min-width: 250px !important;
            max-width: 70vw !important;
            border-radius: 0px !important;
            color: #fff;
            min-height: 45px !important;
            text-transform: initial !important;
            justify-content: space-between;
            padding: 0px 20px;
            font-size: 1rem;
            svg {
                opacity: 0.5;
            }
        }
        .MuiCollapse-wrapper {
            opacity: 0.5;
            background: rgba(0, 0, 0, 0.3);
        }
    }
}

// Mobile - Tablet ------------------------------------------------
.mobile-layout,
.tablet-layout {
    .main-menu {
        // position: relative;
        width: 100%;
        .MuiButtonBase-root {
            position: absolute;
            right: 0px;
            min-width: unset !important;
            padding: 15px !important;
            margin-right: 15px;
        }
    }
}
