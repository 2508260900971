body {
  background-color: #111116;
  color: #fff;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}
.bg-content {
  // background-color: #1f2733;
  border-radius: 35px;
  padding: 20px;
}
.gray-bg {
  background-color: #353d4b;
}
.mt-30 {
  margin-top: 30px;
}
.mt-20 {
  margin-top: 20px !important;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.pd-20 {
  padding: 20px;
}
.br-5 {
  border-radius: 15px;
}
.br-20 {
  border-radius: 20px;
}
.text-center {
  text-align: center;
}
.white-color {
  color: #fff !important;
}
.statistic {
  display: flex;
  background-color: #00a2ff;
  border-radius: 15px;
  padding: 20px;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  .key {
    color: #fff;
    font-size: 0.95em;
    opacity: 0.8;
  }
  .MuiButton-root {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    width: 125px;
  }
}
.pool {
  position: relative;
  text-align: center;
  .text-progress {
    min-height: 40px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    opacity: 0.7;
  }
  .process {
    width: 50%;
    margin: -5px auto 0px;
    .MuiLinearProgress-root {
      border-radius: 50px;
      height: 5px;
      background: rgba(255, 255, 255, 0.1);
      .MuiLinearProgress-bar {
        background: #fff;
      }
    }
  }
}
.countdown {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  > div {
    width: 45px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    text-align: center;
    margin: 0 5px;
    border-radius: 5px;
    line-height: 1.1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label {
      font-size: 0.6em;
    }
    .value {
      font-size: 1.5em;
      font-weight: 600;
    }
  }
}
.MuiDivider-root {
  background-color: rgba(255, 255, 255, 0.12) !important;
}
.dashboard-option {
  display: flex;
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  > div {
    border-radius: 20px;
    font-weight: 600;
    padding: 10px 20px;
    cursor: pointer;
    text-align: center;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    &.active {
      background-color: #353d4b;
    }
  }
}
.green-color {
  color: #88fa4e;
}
.opacity-08 {
  opacity: 0.8 !important;
}
.ui.page.dimmer {
  z-index: 9999;
}

body,
#root {
  position: relative;
}
.App {
  // min-height: 100vh;
  .title {
    text-transform: none !important;
  }
  .MuiTableCell-head {
    font-weight: 500;
  }
  .MuiTableCell-root {
    border-color: rgba(255, 255, 255, 0.12) !important;
  }
  .MuiTableCell-head,
  .MuiTableCell-body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    color: #fff;
  }
  .MuiTablePagination-toolbar {
    color: #fff;
    .MuiIconButton-root.Mui-disabled {
      color: rgba(255, 255, 255, 0.26);
    }
  }
  .show-mobile {
    display: none !important;
  }
}

.MuiSelect-icon,
.MuiRadio-root {
  color: #fff !important;
}
.MuiInputLabel-animated {
  color: rgba(255, 255, 255, 0.42) !important;
}
.MuiInput-underline:before {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
}
.MuiInput-underline:after {
  border-color: #fff !important;
}

.w-100-pc.MuiMenuItem-root {
  width: 100% !important;
}

.custom-modal-vk {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  overflow-x: hidden !important;
  .MuiInputBase-root {
    color: #fff;
  }
  .MuiMenuItem-root {
    .item {
      display: flex;
    }
  }
  .create-stake-form {
    color: #fff;
    display: flex;
    .reward {
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: 35% 50% !important;
      color: #fff;
      width: 200px;
      min-height: 300px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: auto !important;
      .logo-text {
        margin-left: 0;
        margin-top: 15px;
      }
    }
    .form {
      padding: 30px 20px;
      width: 500px;
      min-height: 500px;
      position: relative;
      height: 100%;
      &.policy {
        height: 500px;
      }
      #select-coin {
        .item,
        .MuiMenuItem-root {
          display: flex;
          justify-content: space-between;
        }
      }
      fieldset {
        margin-bottom: 1em;
      }
      .MuiButton-root {
        color: #fff;
        font-weight: 600;
        transition: all 0.2s ease-in-out;
        margin-top: 20px;
      }
    }
  }
  .MuiSvgIcon-root.close-icon {
    fill: rgba(255, 255, 255, 0.12);
    font-size: 2em;
    position: absolute;
    right: 0.2em;
    top: 0.2em;
    cursor: pointer;
    z-index: 3;
    &:hover {
      fill: #fff;
    }
  }
  .custom-modal-vk {
    filter: blur(8px);
  }
  > div:nth-child(3) {
    height: fit-content;
    width: fit-content !important;
    background: transparent;
    display: grid;
    grid-template-rows: min-content auto;
    margin: auto;
    flex: unset;
    position: relative;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 1;
    justify-content: center;
    align-items: center;
    display: none;
    &.active {
      display: flex;
    }
  }
}

.Toastify__close-button--default {
  color: #fff !important;
}

.animation-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  opacity: 0.4;
  z-index: 1;
}

.MuiInputBase-input.Mui-disabled {
  color: #fff;
  opacity: 0.6 !important;
}

#left {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* If you make it 100%, you get a bit of black showing along the diagonal */
  height: 100%;
  background: #1d2833;
  -webkit-clip-path: polygon(0 0, 76% 0, 24% 100%, 0% 100%);
  clip-path: polygon(0 0, 76% 0, 24% 100%, 0% 100%);
}

#right {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  -webkit-clip-path: polygon(76% 0, 100% 0, 100% 100%, 24% 100%);
  clip-path: polygon(76% 0, 100% 0, 100% 100%, 24% 100%);
}

.top-up {
  height: 300px;
  width: 700px!important;
  .MuiGrid-item{
    padding: 20px;
  }
}

@media screen and (max-width: 600px) {
  .top-up {
    width: 300px !important;
    background: #1d2833 !important;
    min-height: 560px !important;
    text-align: center;
    .MuiGrid-item:last-child{
      background-color: rgba(255, 255, 255, 1);
    }
    img {
      margin-bottom: 30px;
      width: 100% !important;
      object-fit: contain;
    }
    .MuiGrid-container {
      flex-direction: column-reverse;
    }
  }
  #right,
  #left {
    clip-path: none;
    background-color: transparent;
  }
}

@media screen and (max-width: 768px) {
  .animation-background {
    background-position: 50% 50% !important;
  }
  .App {
    padding-bottom: 100px;
    .MuiFab-extended.MuiFab-sizeSmall {
      right: 10px !important;
      bottom: 10px !important;
    }
    padding-top: 0 !important;
    .btn-cancel {
      padding: 3px 10px !important;
      font-size: 0.8rem !important;
    }
    .MuiGrid-spacing-xs-2 {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      .MuiGrid-item {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .dashboard-option {
      width: 100%;
      overflow: auto;
    }
    .dashboard-option > div {
      min-width: 180px;
      padding-left: 0;
      padding-right: 0;
    }
    .user-menu .MuiContainer-root a {
      font-size: 1.1em;
      padding: 5px 0;
    }
    .mobile-p-0 {
      padding: 0 !important;
    }
    .mobile-br-0 {
      border-radius: 0;
    }
    .mobile-pb-10 {
      padding-bottom: 10px !important;
    }
    .mobile-pt-10 {
      padding-top: 10px !important;
    }
    .mobile-mt-10 {
      margin-top: 10px !important;
    }
    .mobile-ml-20 {
      margin-left: 20px !important;
    }
    .mobile-mr-20 {
      margin-right: 20px !important;
    }
    .mobile-pl-20 {
      padding-left: 20px !important;
    }
    .mobile-pr-20 {
      padding-right: 20px !important;
    }
    .mobile-mb-10 {
      margin-bottom: 10px !important;
    }
    .bg-content {
      padding: 0;
      background-color: transparent;
    }
    .hide-mobile {
      display: none !important;
    }
    .show-mobile {
      display: block !important;
    }
    .show-mobile-table-row {
      display: table-row !important;
    }
  }
  .custom-modal-vk {
    .create-stake-form {
      display: block;
      h5 {
        font-size: 1em;
      }
      .reward {
        height: 200px !important;
        min-height: unset !important;
      }
      .reward,
      .form {
        width: 100vw;
      }
    }
  }
}

#toolbar {
  z-index: 4;
  position: fixed;
  bottom: 10px;
  right: 20px;
  .JVpTI,
  .kaZhUx {
    background-color: #fff;
    svg {
      fill: #000;
    }
  }
}

.affiliate {
  .tooltip {
    padding: 0;
    margin-left: 5px;
    min-width: auto !important;
    .MuiSvgIcon-root {
      font-size: 1em !important;
    }
  }
}
.tooltip {
  div {
    font-size: 1em;
  }
  .MuiSvgIcon-root {
    fill: rgba(255, 255, 255, 0.2) !important;
    opacity: 1;
  }
}

.release-process-bar {
  height: 25px;
  border-radius: 3px;
  margin: 20px 0px 5px;
  background: rgba(255, 255, 255, 0.08);
  .days {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: 600;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
    position: relative;
    > span {
      position: relative;
      z-index: 1;
      white-space: nowrap;
      opacity: 0.9;
    }
    &.top {
      justify-content: flex-start !important;
      > span {
        bottom: 100% !important;
        right: 0px !important;
      }
    }
  }
  .lus {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: 600;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
    position: relative;
    > span {
      position: relative;
      z-index: 1;
      white-space: nowrap;
    }
    &.top {
      justify-content: flex-end !important;
      > span {
        bottom: 100% !important;
        right: 0px !important;
      }
    }
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background: linear-gradient(to right, #02c7b0, #099289);
      border-radius: 0px 3px 3px 0px;
      top: 0px;
      right: 0px;
      opacity: 0.6;
      z-index: 0;
    }
    &.circular::before {
      border-radius: 3px !important;
    }
  }
}

.brands {
  padding: 1.5em 0;
  background-color: #fff;
  margin-bottom: -10px;
  .item {
    text-align: center;
  }
  img {
    width: 70%;
    height: 100px;
    object-fit: contain;
  }
}

.color-EE220C {
  color: #ee220c !important;
}

.swap-banner {
  height: 100px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  cursor: pointer;
  .text {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: -2px;
    right: 0;
    color: #000;
    -webkit-animation: flickerAnimation 1s infinite;
    -moz-animation: flickerAnimation 1s infinite;
    -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
    font-size: 10px;
    font-weight: bold;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: inset;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
