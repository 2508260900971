@import "./color.scss";
.MuiAppBar-root {
  margin: 0px !important;
}
.menu {
  padding: 20px 0px;
  &:hover {
    .submenu {
      display: inherit;
    }
  }
}
.submenu {
  position: absolute;
  background: #1f232b !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: hidden;
  display: none;
  &:hover {
    display: inherit !important;
  }
  li {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

#home-page {
  padding-top: 68px;
  .reward {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: 0 50% !important;
    color: #fff;
    > div {
      background: rgba(0, 0, 0, 0.3);
      padding: 100px 100px !important;
      .MuiGrid-root {
        justify-content: space-between !important;
      }
      .logo-text {
        font-size: 3.2rem;
        > label {
          font-weight: 800;
        }
        > span {
          font-weight: 200;
        }
      }
      p {
        margin-bottom: 20px;
        max-width: 70%;
        font-size: 1.1rem;
      }
    }
  }
  p.decs {
    margin-bottom: 10px;
    max-width: 80%;
    margin-top: 10px;

    > label {
      font-size: 1.2rem;
    }
    > p {
      opacity: 0.7;
    }
  }
}
.services {
  background: #20232a !important;
  padding: 10px 0;
  img {
    width: 20% !important;
  }
}
.supply-chart {
  background: #20232a;
  //   height: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  p {
    font-size: 1.3rem;
    font-weight: bold;
    opacity: 0.5;
  }
  img {
    width: 90%;
  }
}
.customer {
  background: #fff;
  overflow: auto;
}
.roadmap {
  background: #1f232b;
  color: #fff;
  padding: 50px 0px !important;
  label.title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    font-size: 1.5rem;
  }
  .roadmap-content {
    display: grid;
    grid-template-columns: 40% 60%;
    > div {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      align-content: space-between;
      padding-bottom: 40px;
      > div {
        text-align: right;
        img {
          width: 65%;
          height: auto;
        }
      }
    }
    > ul {
      li {
        display: grid;
        grid-template-columns: 100px 50px calc(100% - 150px);
        grid-template-areas: "year blank info";
        > div {
          p {
            width: 70%;
            padding: 5px 0px 30px;
            opacity: 0.5;
          }
          &:nth-child(1) {
            grid-area: year;
            text-align: right;
          }
          &:nth-child(2) {
            grid-area: blank;
            position: relative;
            &::before {
              content: "";
              width: 14px;
              height: 14px;
              border-radius: 20px;
              position: absolute;
              top: 4px;
              left: calc(50% - 7px);
              background: #b29f78;
            }
            &::after {
              content: "";
              width: 2px;
              height: calc(100% - 30px);
              background: rgba(255, 255, 255, 0.4);
              position: absolute;
              top: 25px;
              left: calc(50% - 1px);
            }
          }
          &:nth-child(3) {
            grid-area: info;
          }
        }
        &:last-child {
          div.blank {
            &:after {
              display: none !important;
            }
          }
        }
      }
    }
  }
}
.footer {
  background-color: #333333;
  padding: 5em 0;
  font-size: 1.2em;
  p {
    color: #fff;
    height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 !important;
    max-width: 100% !important;
  }
  .social-media {
    font-family: Media;
    padding: 1em 0;
    font-size: 1.5em;
  }
  a {
    text-decoration: unset !important;
  }
  a p {
    color: #d9c8ac;
    height: auto;
  }
  .logo {
    height: 40px;
  }
}
@media screen and (max-width: 768px) {
  #home-page {
    .total-supply {
      .supply-chart {
        padding: 20px;
      }
    }
    .roadmap .roadmap-content > ul li {
      grid-template-columns: 100px 50px calc(100%);
    }
    .reward {
      > div {
        padding: 100px 0 !important;
      }
    }
  }
  .footer {
    padding: 2rem 0px 5rem;
    .MuiContainer-root {
      .MuiGrid-container {
        .MuiGrid-root {
          width: 100%;
          flex-basis: 100%;
          max-width: 100%;
          min-width: 100%;
          padding: 1rem 2rem;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          &:last-child {
            border-bottom: none;
          }
          > div {
            width: 100%;
            text-align: center;
          }
          .d-flex-cs {
            padding: 0px;
          }
        }
      }
    }
  }
}

//Mobile ---------------------------------------
.mobile-layout {
  #home-page {
    .reward {
      background-position: 35% 50% !important;
      > div {
        padding: 30px 30px !important;
        > div {
          padding: 0px !important;
          p {
            max-width: 100% !important;
          }
        }
        > .MuiGrid-root {
          display: grid !important;
          grid-template-columns: repeat(2, 1fr);
          padding: 1rem !important;
          margin-top: 40px !important;
          grid-column-gap: 2rem;
          grid-row-gap: 2rem;
          > div {
            min-width: 100%;
            border-radius: 5px;
            background: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(5px);
            box-shadow: 0px 0px 1px rgba(255, 255, 255, 0.5);
            img {
              height: 50px !important;
              width: auto !important;
            }
            p {
              &:last-child {
                white-space: nowrap;
                font-size: 3vw;
              }
            }
          }
        }
      }
    }
    .services {
      > div {
        > div {
          padding: 30px !important;
          display: flex !important;
          flex-wrap: wrap !important;
          img {
            width: 40% !important;
            height: auto !important;
            justify-content: center;
            align-content: center;
            &:nth-child(1),
            &:nth-child(2) {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
    .total-supply {
      display: flex !important;
      flex-wrap: wrap !important;
      padding: 30px !important;
      > div {
        width: 100% !important;
        .decs {
          p {
            font-size: 0.9rem !important;
          }
        }
      }
      .supply-chart {
        border-radius: 10px !important;
        // padding: 40px 0px 30px !important;
        p {
          margin-top: 20px;
          font-size: 3.5vw;
        }
      }
    }
    .supply-chart img {
      height: auto !important;
    }
    .customer {
      > div {
        > div {
          padding: 20px !important;
          overflow-x: scroll;
          img {
            margin-right: 30px;
          }
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
    .roadmap {
      .roadmap-content {
        display: block !important;
        > div {
          display: none;
        }
        > ul {
          li {
            grid-template-columns: 80px 50px calc(100% - 130px) !important;
            .info {
              p {
                width: 100%;
                padding-right: 30px;
              }
            }
          }
        }
      }
    }
  }
}

//Tablet ---------------------------------------
.tablet-layout {
  #home-page {
    .reward {
      > div {
        padding: 100px 30px !important;
        > div {
          padding: 0px !important;
          p {
            max-width: 100% !important;
          }
          button {
            display: none;
          }
        }
        > .MuiGrid-root {
          display: grid !important;
          grid-template-columns: repeat(2, 1fr);
          padding: 1rem !important;
          margin-top: 40px !important;
          grid-column-gap: 2rem;
          grid-row-gap: 2rem;
          > div {
            min-width: 100%;
            border-radius: 5px;
            background: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(5px);
            box-shadow: 0px 0px 1px rgba(255, 255, 255, 0.5);
            img {
              height: 50px !important;
              width: auto !important;
            }
            p {
              &:last-child {
                white-space: nowrap;
                font-size: 15px;
              }
            }
          }
        }
      }
    }
    .services {
      > div {
        > div {
          padding: 30px !important;
          display: flex !important;
          align-items: center;
          flex-wrap: wrap !important;
          img {
            width: 25% !important;
            height: auto !important;
            justify-content: center;
            align-content: center;
            padding: 0px 20px;
          }
        }
      }
    }
    .total-supply {
      display: flex !important;
      flex-wrap: wrap !important;
      padding: 30px !important;
      > div {
        width: 100% !important;
        .decs {
          p {
            font-size: 0.9rem !important;
          }
        }
      }
      .supply-chart {
        border-radius: 10px !important;
        padding: 40px 0px 30px !important;
        p {
          margin-top: 20px;
          font-size: 3.5vw;
        }
      }
    }
    .customer {
      > div {
        > div {
          padding: 20px !important;
          overflow-x: scroll;
          img {
            margin-right: 30px;
          }
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
    .roadmap {
      .roadmap-content {
        display: block !important;
        > div {
          display: none;
        }
        > ul {
          li {
            grid-template-columns: 80px 50px calc(100% - 130px) !important;
            .info {
              p {
                width: 100%;
                padding-right: 30px;
              }
            }
          }
        }
      }
    }
  }
}

#homepage-statistic > .MuiGrid-container {
  height: 250px;
  background: rgba(32, 35, 42, 0.7);
  padding: 30px 0px !important;
  padding-top: 2em;
}
.reward div#homepage-statistic {
  margin-left: -8em;
  margin-right: -8em;
  margin-bottom: -8em;
}
.reward div#homepage-statistic p {
  max-width: 100% !important;
  margin: 0 !important;
  text-align: center;
}

.reward div#homepage-statistic .MuiTypography-body1 {
  font-weight: 600;
}

.mobile-layout,
.tablet-layout {
  #home-page {
    .roadmap{
      margin-bottom: -15px;
    }
    .wrapper{
      margin-bottom: 100px;
      height: 70%;
    }
    #homepage-statistic > .MuiGrid-container {
      height: 500px;
      margin-top: 50px;
    }
    .reward div#homepage-statistic {
      margin-left: -2.3em;
      margin-right: -2.3em;
      margin-bottom: -2.3em;
    }
    #homepage-statistic > .MuiGrid-container {
      padding: 30px 0 !important;
    }
  }
}
