$main_blue_color: rgba(115, 253, 234, 1);
$main_opacity_blue_color: rgba(115, 253, 234, 0.3);
$light_blue: #00a89d;
$white: rgba(255, 255, 255, 1);
$black: rgba(0, 0, 0, 1);
$red: #ff644e;
@import "./color.scss";
.App {
  // padding-top: 68px;
  // background: $bg-dark;
  background-color: rgba(0, 0, 0, 0.6);
  min-height: 100vh;
  z-index: 2;
  position: relative;
  p {
    margin: 0px;
  }
}
.MuiContainer-root {
  padding: 0px !important;
}
.radius-none {
  border-radius: none !important;
}
.bg-light {
  background: rgba(255, 255, 255, 0.1) !important;
}
.bg-dark {
  background: $bg-dark !important;
  background-color: $bg-dark !important;
}
.bg-dark-01 {
  background: $bg-dark-01 !important;
  background-color: $bg-dark-01 !important;
}
.bg-none {
  background: none !important;
}
.bg-00a89d {
  background: #00a89d !important;
}
.bg-primary {
  background: $primary;
}
.bg-secondary {
  background: $red;
}
.color-white {
  color: #fff;
}
.color-gray {
  color: #828991;
}
.color-primary {
  color: $primary;
}
.color-secondary {
  color: $red;
}
.color-yellow,
#color-yellow {
  color: $yellow !important;
}
.h-28 {
  height: 28px;
}
.h-32 {
  height: 32px;
}
.h-35 {
  height: 35px;
}
.h-38 {
  height: 38px;
}
.h-50 {
  height: 50px;
}
.h-55 {
  height: 55px;
}
.h-60 {
  height: 60px !important;
}
.h-70 {
  height: 70px !important;
}
.h-100 {
  height: 100px !important;
}
.h-150 {
  height: 150px !important;
}
.h-50pc {
  height: 50% !important;
}
.h-100pc {
  height: 100% !important;
}
.w-20 {
  width: 20px;
}
.w-32 {
  width: 32px !important;
}
.w-38 {
  width: 38px;
}
.w-50 {
  width: 50px;
}
.w-60 {
  display: inline-block;
  width: 60px !important;
}
.w-100 {
  display: inline-block;
  width: 100px !important;
}
.w-150 {
  width: 150px !important;
}
.w-170 {
  width: 170px !important;
}
.w-200 {
  width: 200px !important;
}
.w-300 {
  width: 300px !important;
}
.w-400 {
  width: 400px !important;
}
.w-500 {
  width: 500px !important;
}
.w-35pc {
  width: 35%;
  min-width: 35%;
  max-width: 35%;
}
.w-40pc {
  width: 40%;
  min-width: 40%;
  max-width: 40%;
}
.w-50pc {
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}
.w-60pc {
  width: 60%;
  min-width: 60%;
  max-width: 60%;
}
.w-70pc {
  width: 70%;
  min-width: 70%;
  max-width: 70%;
}
.w-80pc {
  width: 80%;
  min-width: 80%;
  max-width: 80%;
}
.w-100pc {
  display: inline-block;
  width: 100% !important;
  min-width: 100% !important;
}
.min-w-70 {
  min-width: 70px !important;
}
.min-w-80 {
  min-width: 80px !important;
}
.min-w-100 {
  min-width: 100px;
}
.min-w-130 {
  min-width: 130px !important;
}
.min-w-150 {
  min-width: 150px !important;
}
.min-w-160 {
  min-width: 160px !important;
}
.min-w-200 {
  min-width: 200px !important;
}
.min-w-300 {
  min-width: 300px !important;
}
.min-w-500 {
  min-width: 500px !important;
}
.min-h-20 {
  min-height: 20px !important;
}
.min-h-30 {
  min-height: 30px !important;
}
.min-h-38 {
  min-height: 38px !important;
}
.min-h-50 {
  min-height: 50px !important;
}
.min-h-150 {
  min-height: 150px !important;
}
.min-h-170 {
  min-height: 170px !important;
}
.min-h-200 {
  min-height: 200px !important;
}
.min-h-250 {
  min-height: 250px !important;
}
.min-h-300 {
  min-height: 300px !important;
}
.min-h-340 {
  min-height: 340px !important;
}
.min-h-460 {
  min-height: 460px !important;
}
.min-h-400 {
  min-height: 400px !important;
}
.min-h-550 {
  min-height: 550px !important;
}

.min-h-600 {
  min-height: 600px !important;
}
.min-h-675 {
  min-height: 675px !important;
}
.max-w-70 {
  max-width: 70px !important;
}
.max-w-80 {
  max-width: 80px !important;
}
.max-w-100 {
  max-width: 100px !important;
}
.max-w-200 {
  max-width: 200px !important;
}
.max-w-250 {
  max-width: 250px !important;
}
.max-w-300 {
  max-width: 300px !important;
}
.max-w-400 {
  max-width: 400px !important;
}
.max-w-500 {
  max-width: 500px !important;
}
.max-w-100pc {
  max-width: 100pc !important;
}
.max-h-32 {
  max-height: 32px;
}
.p-0 {
  padding: 0px !important;
}
.p-3 {
  display: inline-block;
  padding: 3px !important;
}
.p-5 {
  padding: 5px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-40 {
  padding: 40px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pl-85 {
  padding-left: 85px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.p-10-0 {
  padding: 10px 0px !important;
}
.p-50-0 {
  padding: 50px 0px !important;
}
.m-0 {
  margin: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.m-5 {
  margin: 5px !important;
}
.m-10 {
  margin: 10px !important;
}
.m-5-0 {
  margin: 5px 0px !important;
}
.m-10-0 {
  margin: 10px 0px !important;
}
.m-15-0 {
  margin: 15px 0px !important;
}
.m-0-5 {
  margin: 0px 5px !important;
}
.m-0-10 {
  margin: 0px 10px !important;
}
.m-0-auto {
  margin: 0px auto !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-50 {
  margin-top: 30px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mt-100 {
  margin-top: 100px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}
.d-flex-sbt {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.d-flex-ebt {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.d-flex-ee {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.d-flex-ss {
  display: flex !important;
  align-items: flex-start;
  justify-content: flex-start;
}
.d-flex-sc {
  display: flex !important;
  align-items: flex-start;
  justify-content: center;
}
.d-flex-cs {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
}
.d-flex-ce {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.d-flex-cc {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.d-flex-cbt {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.d-flex-es {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.d-flex-ec {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.d-inline-flex-cc {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.text-left {
  display: inline-block;
  text-align: left;
  width: 100%;
}

.text-center {
  display: inline-block;
  text-align: center;
}
.text-right {
  display: inline-block;
  text-align: right;
  width: 100%;
}
.nowrap {
  white-space: nowrap;
}
.p-5-20 {
  padding: 5px 20px !important;
}
.p-0-20 {
  padding: 0px 20px !important;
}
.p-5 {
  display: inline-block !important;
  padding: 5px !important;
}
.p-10 {
  display: inline-block !important;
  padding: 10px !important;
}
.p-100 {
  padding: 100px !important;
}
.pr-50 {
  padding-right: 50px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}
.pb-80 {
  padding-bottom: 80px !important;
}
.font-size-default {
  font-size: 16px !important;
}
.font-size-07 {
  font-size: 0.7rem !important;
}
.font-size-08 {
  font-size: 0.8rem !important;
}
.font-size-09 {
  font-size: 0.9rem !important;
}
.font-size-10 {
  font-size: 1rem !important;
}
.font-size-11 {
  font-size: 1.1rem !important;
}
.font-size-12 {
  font-size: 1.2rem !important;
}
.font-size-13 {
  font-size: 1.3rem !important;
}
.font-size-14 {
  font-size: 1.4rem !important;
}
.font-size-15 {
  font-size: 1.5rem !important;
}
.font-size-17 {
  font-size: 1.7rem !important;
}
.text-normal {
  font-weight: normal;
}
.text-bold {
  font-weight: bold !important;
}
.opacity-1 {
  opacity: 1 !important;
}
.opacity-04 {
  opacity: 0.4;
}
.opacity-06 {
  opacity: 0.6;
}
.opacity-0 {
  opacity: 0;
}
.number-light {
  font-family: "SP", "Number-Light" !important;
  font-size: 1rem !important;
}
.number-normal {
  font-family: "SP", "Number-Normal" !important;
}
.up {
  color: $primary !important;
}
.down,
#down {
  color: $red !important;
}
.bg-down {
  background-color: $red !important;
}
.bg-up {
  background-color: $primary !important;
}
.btn-bg-gradient {
  background: linear-gradient(to right, #00a2ff, #ff8dc6) !important;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  // font-family: "SP" !important;
}
.background-none {
  background: none !important;
}
.background-light {
  background: #fff;
}
.dark-mode {
  .error-message {
    padding: 15px;
    background: transparent;
    border-radius: 5px;
    color: #ff7c7c;
  }
  .bg-dark {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .Mui-selected {
    background: rgba(255, 255, 255, 0.1) !important;
  }
  .background-light {
    background: $bg-light;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }
  .absolute {
    background: $bg-light-03 !important;
  }
  .btn-cancel-light {
    background: rgba(255, 255, 255, 0.1) !important;
    color: rgba(255, 255, 255, 0.9) !important;
  }
  .up {
    color: $primary-light !important;
  }
  .color-primary {
    color: $primary-light !important;
  }
}
.z-index-100 {
  z-index: 100 !important;
}
.shadow-default {
  box-shadow: 0 2px 7px 0 rgba(34, 36, 38, 0.1) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.border-light {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.border-none {
  border: none !important;
  outline: none !important;
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.hover-underline:hover {
  text-decoration: underline !important;
}
.hover-color {
  &:hover {
    color: $primary !important;
  }
}
.btn-cancel-light {
  background: rgba(0, 0, 0, 0.05) !important;
  border-radius: 100px !important;
  color: rgba(0, 0, 0, 0.9) !important;
  font-weight: normal !important;
  &:hover {
    background: rgba(0, 0, 0, 0.1) !important;
  }
}
.btn-submit {
  background: $primary !important;
  color: #fff !important;
  padding: 5px 20px !important;
  text-transform: initial !important;
  font-size: 1rem !important;
}
.btn-cancel {
  background: rgba(255, 255, 255, 0.09) !important;
  color: #fff !important;
  padding: 5px 20px !important;
  text-transform: initial !important;
  font-size: 1rem !important;
}
.backdrop-blur {
  background: rgba(0, 0, 0, 0.4) !important;
  backdrop-filter: blur(10px);
}
.gradient {
  background: linear-gradient(to right, #004d7f, #16e7cf) !important;
}
.color-gradient {
  background: -webkit-linear-gradient(#16e7cf, #004d7f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold !important;
}
.radius-none {
  border-radius: 0px !important;
}
.radius-larg {
  border-radius: 7px !important;
}
ul {
  padding: 0px;
  list-style-type: none;
}
i.icon.copy {
  width: fit-content !important;
  height: fit-content !important;
  margin: 0px;
}
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.circular {
  border-radius: 100px !important;
}
#bg-none {
  background: none !important;
}
.warning-message {
  padding: 15px;
  background: #fffcf1;
  border-radius: 5px;
  color: $yellow;
  display: flex;
  align-items: flex-start;
  > span {
    margin-left: 10px;
  }
}
.dark-mode {
  .warning-message {
    padding: 15px;
    background: #fffcf12d;
    border-radius: 5px;
    color: $yellow;
  }
}
.error-message {
  padding: 15px 0;
  background: transparent;
  border-radius: 5px;
  color: #d36868;
}
.success-message {
  padding: 15px;
  background: #eefdf6;
  border-radius: 5px;
  color: #359166;
}
.status_success {
  // padding: 3px 10px;
  // background: #5effb43d;
  // border-radius: 5px;
  color: #20c578;
  font-weight: bold;
}
.status_new {
  // padding: 3px 10px;
  // background: #fff9e7;
  // border-radius: 5px;
  color: #efb909;
  font-weight: bold;
}
.yellow {
  color: $yellow !important;
}
.status_default {
  // padding: 3px 10px;
  // background: #dbdbdb2a;
  // border-radius: 5px;
  color: #bbb;
  font-weight: bold;
}
.ui.divider {
  margin-top: 0px !important;
}
.number-input {
  position: relative;
  button {
    position: absolute;
    right: -2px;
    bottom: 1px;
  }
}
.Toastify__progress-bar,
.Toastify__progress-bar--default {
  background: hsla(0, 0%, 66.7%, 0.8) !important;
  height: 3px !important;
}
.Toastify__toast.Toastify__toast--default {
  background: #18202c !important;
  color: var(--white);
}
.MuiButton-outlinedPrimary {
  color: $primary !important;
  border: 1px solid $primary !important;
}
.MuiCheckbox-colorPrimary {
  color: $primary !important;
}
.MuiButton-outlinedSecondary {
  color: $secondary !important;
  border: 1px solid $secondary !important;
}
.MuiButton-textPrimary {
  color: $primary !important;
  &.bg-light {
    background-color: rgba(255, 255, 255, 0.06) !important;
    padding: 5px 20px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }
  }
}
.border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.06) !important;
}
.text-white {
  color: #fff !important;
}
.MuiButton-label {
  text-transform: initial;
  font-size: 1rem;
}
.hidden {
  overflow: hidden;
}
.MuiCircularProgress-root {
  width: 20px !important;
  height: 20px !important;
  svg {
    width: 20px;
    height: 20px;
    color: #fff;
  }
}
.dimmer {
  background: rgba(255, 255, 255, 0.1) !important;
}