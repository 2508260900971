//PC Layout -----------------------------------------------
.app-header {
    height: 68px !important;
    #tool-bar{
        padding: 0px 6em !important;
    }
    .MuiToolbar-root {
        height: 68px !important;
        padding: 0px !important;
    }
    .logo-text {
        margin-left: 15px;
        font-size: 1.5rem;
        > label {
            font-weight: 800;
        }
        > span {
            font-weight: 200;
        }
    }
    button {
        color: #fff;
        text-transform: initial;
        min-width: 120px;
        overflow: hidden;
        font-size: 1rem;
    }
}

// Mobile -----------------------------------------------
.mobile-layout,
.tablet-layout {
    #tool-bar{
        padding: 0px 6em 0px 2.5em !important;
    }
}
