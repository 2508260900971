.user-menu {
  background-color: #1d2833 !important;
  color: #fff;
  padding: 5px;
  margin-bottom: 30px;
  transition: all 0.2s ease-in-out;
  .MuiContainer-root {
    display: flex;
    padding: 5px 20px !important;
    .logo {
      > span {
        font-size: 1.7rem;
        margin-left: 10px;
      }
    }
    .logo-text {
      font-size: 1.8rem;
      > label {
        font-weight: 700;
      }
      > span {
        font-weight: 200;
      }
    }
    .menu {
      padding: 0px !important;
      a {
        flex: 1;
        display: block;
        text-align: center;
        padding: 10px 0;
        border-radius: 35px;
        font-size: 1.3em;
        font-weight: 300;
        text-decoration: none;
        color: #fff;
        transition: all 0.2s ease-in-out;
        white-space: nowrap;
        padding: 10px 25px;
        border-radius: 50px;
        &:hover {
          opacity: 0.8;
          background: rgba(255, 255, 255, 0.1);
        }
        &.active {
          // background-color: #000;
        }
      }
      .MuiAvatar-root {
        margin-left: 20px;
      }
    }
  }
}

//Tablet ---------------------------------------------------
.tablet-layout,
.mobile-layout {
  .user-menu {
    background-color: #1d2833 !important;
    color: #fff;
    padding: 5px;
    margin-bottom: 30px;
    .MuiContainer-root {
      > div {
        display: grid !important;
        grid-template-columns: min-content auto;
        grid-template-areas: "logo text1 menu" "logo text1 menu";
        justify-content: unset !important;
        .MuiButtonBase-root.MuiIconButton-root {
          color: #fff;
          margin-left: 10px;
          padding: 5px;
          svg {
            width: 30px;
            height: 30px;
            opacity: 0.8;
          }
        }
        .MuiAvatar-root {
          grid-area: logo;
          margin-right: 10px;
        }
        .logo {
          grid-area: text1;
          span {
            font-size: 1rem !important;
            margin: 0px;
          }
        }
        .logo-text {
          grid-area: text2;
          font-size: 1.5rem !important;
        }
        .menu {
          grid-area: menu;
        }
      }
    }
  }
}

.user-menu-drawer {
  .MuiPaper-root.MuiDrawer-paper {
    // background-color: #1d2833 !important;
    > a {
      min-width: 250px;
      max-width: 70vw;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 45px;
      padding: 0px 20px;
      font-size: 1.1rem;
      color: #fff;
      &:hover {
        background: rgba(255, 255, 255, 0.09);
      }
    }
  }
}
